<template lang="pug">
v-card.primary.lighten-5.ma-3.pointer(
  @click="$router.push({ name: 'results', params: { id: dinamica.id } })"
)
  v-toolbar.primary
    .row
      .col.text-center
        h2.ml-2 {{ dinamica.name }}
  .container
    .row(style="background-color: rgb(255, 255, 255,0.25)")
      .col.text-center
        //- | {{dinamica}}
        p {{ dinamica.place }}
        p {{ dinamica.date | simpledate }}
        p {{ dinamica.groups + ' ' + $words.get('groups') }}
</template>
<script>
import moment from 'moment'
export default {
  name: 'DinamicaCard',
  props: ['dinamica'],
  filters: {
    simpledate (date) {
      if (date) return moment(date.toDate()).format('dd, DD MMMM YYYY')
      else console.log(date)
    }
  }
}
</script>
