<template lang="pug">
v-dialog(v-model="dialog", :max-width="!maxWidth ? '500px':maxWidth")
  template(v-slot:activator="{ on }", v-if="activator")
    v-btn.mb-2(color="secondary", dark="dark", @click="open") {{buttomText}}

  v-card(:color="!modalColor ? null :modalColor", :dark="modalDark ? true : false")
    v-toolbar.terciary.text-center.display-1.white--text
      strong Nueva Dinamica

    v-container.pa-5
      v-form(ref="_formWrapper", v-model="valid", :lazy-validation="true")
        .container.pa-5
          .row
            .col
              v-text-field(:label="$words.get('nombre')", v-model="form.name", :error-messages="errors.name", :rules="[valrules.required]")
          .row
            .col
              v-text-field(:label="$words.get('place')",v-model="form.place", :rules="[valrules.required]", :error-messages="errors.place")
          .row
            .col
              v-menu(ref="fechamenu"
                  v-model='datemenu'
                  :close-on-content-click='false'
                  transition='scale-transition'
                  offset-y
                  min-width='290px')
                  template(v-slot:activator='{ on, attrs }')
                    v-text-field(
                      v-model='form.date'
                      :label="$words.get('fecha')"
                      :error-messages="errors.date"
                      prepend-icon='fas fa-calendar-alt'
                      v-bind="attrs"
                      readonly
                      v-on='on')
                  v-date-picker(v-model='form.date'
                  @input="datemenu = false" no-title scrollable)
                    .flex-grow-1
          .row
            .col
              v-text-field(
                type='number'
                min='1',
                :label="$words.get('groups')",
                v-model="form.groups",
                :rules="[valrules.required]",
                :error-messages="errors.groups")
            .col
              v-select(
                :items="options"
                item-value="id"
                item-text="text"
                v-model="form.initial"
                :error-messages="errors.initial"
                :placeholder="$words.get('initial_l')"
                :label="$words.get('initial_l')"
                required)
    v-card-actions.justify-center
      v-btn(color="red darken-1", text="text", size="large" @click="close")
        .headline Cancelar

      v-btn(color="green darken-1", text="text",  large :disabled="!valid || loading", :loading="loading" @click="sendForm")
        .headline Guardar

</template>
<script>
import { formModal } from '#/Modal'
import alfabeto from '@/assets/afabeto'

export default {
  props: { fullmode: { default: false } },
  mixins: [ formModal ],
  methods: {
    async successSend (success) {
      this.$emit('creado')
      console.log(success)
    }
  },
  data () {
    return {
      activator: true,
      maxWidth: null,
      loading: false,
      model_name: 'user',
      buttomText: this.$words.get('nueva_dinamica'),
      customFormTitle: this.$words.get('nueva_dinamica'),
      menu: false,
      // createAction: 'addPerson',
      datemenu: false,
      model: this.$firestore.models.games,
      options: alfabeto.letras,
      nac: 'V',
      form: {
        name: '',
        place: '',
        date: null,
        groups: 1,
        initial: 'A'
      }
    }
  }
}
</script>
