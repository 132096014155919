<template lang="pug">
.container

  .row.justify-space-around.mt-5.px-5

    h1 {{ $words.get('dinamicas_realizadas') }}
    v-spacer
    //- v-btn(color="secondary" @click="$refs.GameForm.open()" ) Crear Dinamica
    GameForm(ref="GameForm" @creado="$refs.GameList.listObjects()")
    v-btn(@click="$router.push({name:'users'})") Usuarios
  hr
  .row.justify-content-center
    GameList(ref="GameList")
</template>
<script>
//
import GameForm from '@/components/game/Form.vue'
import GameList from '@/components/game/List.vue'
// import Loader from '@/components/commons/Loader.vue'

export default {
  name: 'home',
  components: {
    GameForm,
    GameList
    // Loader
  },
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.loading = false
  }
}
</script>
